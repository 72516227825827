import React from 'react';
import {useTranslation} from 'react-i18next';

export default function TermsEnglish() {
  const {t} = useTranslation();

  return (
    <>
      <p>
        <strong>{t('I. Introductory and interpretative provisions')}</strong>
        <br />
        {t(
          '1.1 The company KUBRAK GROUP s.r.o. with its registered office at Klimentská 2066/19, Nové Město, 110 00 Prague 1, ID number 037 68 899, registered in the Commercial Register at the Municipal Court in Prague, Section C, Insert 237455, under the trade name MAGNOLIA - cleaning services (hereinafter the "Company" or " MAGNOLIA ”) provides cleaning services',
        )}
        <br />
        {t('You can find current valid data from the Commercial Register')}{' '}
        <a href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=1163850&typ=UPLNY">
          HERE
        </a>{' '}
        {t('. More information about the Company can be found on the website')}{' '}
        <a href="https://www.magnolia-uklid.cz">
          https://www.magnolia-uklid.cz.
        </a>
        <br />
        {t(
          "These General Terms and Conditions (hereinafter referred to as the “GTC”) govern the relations between the contracting parties for the provision of services within the company's business activities",
        )}
        <br />
        {t(
          '1.2 For the purposes of the GTC, these terms are defined as follows:',
        )}
        <br />
        <ul>
          <li>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `<strong>${t(
                    'Services',
                  )}</strong> mean the services offered by MAGNOLIA's activities, in particular cleaning services; regular and one-time <a target="_blank" href="/en/home-cleaning" style="color: #039259; text-decoration: underline">cleaning of households</a>, with <a href="/en/office-cleaning" style="color: #039259; text-decoration: underline">cleaning of non-residential premises</a>, specialized cleaning services and other related activities.`,
                ),
              }}
            />
          </li>
          <br />
          <li>
            <strong>{t('Subject of cleaning')}</strong>
            {t(' is a specific residential or non-residential space or thing.')}
          </li>
          <br />
          <li>
            <strong>{t('The service provider')}</strong>
            {t(' is the Company.')}
          </li>
          <br />
          <li>
            <strong>{t('Client')}</strong>
            {t(
              ' is a natural or legal person to whom the Services are provided, if the Client is not fully independent or of legal age, it is necessary that the Client is represented by legal representative in all negotiations with MAGNOLIA. Where the GTC impose an obligation on the Client and this client is not fully independent, his legal representative is obliged to fulfill this obligation.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('Consumer')}</strong>
            {t(
              ' is a natural person who does not act within the scope of his business, other entrepreneurial activity, or independent performance of a profession when concluding and fulfilling a contract or other negotiations with MAGNOLIA.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('The Service Provision Agreement')}</strong>
            {t(
              ' is an agreement concluded between the Client and the Company within the framework of the provision of Services.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('GTC')}</strong>
            {t(
              ' are these business conditions in their current version, which are an integral part of the Agreement.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('Cleaning')}</strong>
            {t(
              ' is a specific Service provided by the Company to a specific Client on the basis of the Agreement, at a specifically specified place, time and to a pre-agreed extent, for which the Client is obliged to pay the Company its Price.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('Websites')}</strong>
            {t(' are the MAGNOLIA website on a web link ')}{' '}
            <a href="https://www.magnolia-uklid.cz">
              https://www.magnolia-uklid.cz.
            </a>
          </li>
          <br />
          <li>
            <strong>{t('Price of Services')}</strong>
            {t(' or ')}
            <strong>{t('The Price ')}</strong>{' '}
            {t(
              'means the price at which MAGNOLIA offers its Services. VAT in the current amount is always added to the Client - legal entity to this price.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('Price list')}</strong>
            {t(
              ' means information on the current approximate prices of services, which are listed on the MAGNOLIA website, in the section „',
            )}
            <a href="https://www.magnolia-uklid.cz/cenik-uklidovych-praci">
              Price list
            </a>
            {t('“.')}
          </li>
          <br />
          <li>
            <strong>{t('Price of cleaning')}</strong>
            {t(
              ' is the specific price that the Company will communicate to the Client within the order before the start of the Cleaning.',
            )}
          </li>
          <br />
          <li>
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  `<strong>${t(
                    'Voucher',
                  )}</strong> is a <a target="_blank" href="/en/gift-vouchers" style="color: #039259; text-decoration: underline;">gift voucher</a> issued by MAGNOLIA in a certain nominal value intended for obtaining Services from MAGNOLIA.`,
                ),
              }}
            />
          </li>
          <br />
          <li>
            <strong>{t('Employee')}</strong>
            {t(
              ' means a natural person in an employment or similar relationship with the Company, or any other person cooperating with MAGNOLIA in the provision of Services.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('Additional work')}</strong>
            {t(
              ' means hours worked, or individual Services, beyond the pre-agreed scope of the Cleaning.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('Time Range')}</strong>
            {t(
              ' for the start of the provision of the Services means the specific date and time for the start of the provision of the Services for the Client, which is communicated to the Client by e-mail before the conclusion of the Agreement. The date of provision of the Services is fixed. The Time Range is communicated to the Client when ordering the Services and is indicative.',
            )}
          </li>
          <br />
          <li>
            <strong>{t('End of Cleaning')}</strong>
            {t(
              ' is the moment when the Employee ends the performance of Cleaning and is ready to hand it over to the Client. In case of doubt between the parties about the exact End of Cleaning Hour, the End of Cleaning Hour shall be deemed to be the time following two hours after the lower limit of the Time Period for the commencement of the provision of the Services. For example, if the Time Range is 14-15 hours, the End of Cleaning Time is considered to be 16 hours on a given day in case of doubt.',
            )}
          </li>
        </ul>
      </p>
      <p>
        <strong>
          {t(
            '2 The subjects of the relationship between MAGNOLIA and the Client',
          )}
        </strong>
        <br />
        {t(
          `2.1 The Provider undertakes to provide the Client with the Services in compliance with the conditions under the Agreement and these GTC, and the Client is obliged to pay MAGNOLIA their Price for such Services.`,
        )}
      </p>
      <p>
        <strong>{t('3 Agreement')}</strong>
        <br />
        <li>
          {t(
            `3.1 The Client expresses an interest in the Services being provided to him, and thus in concluding an Agreement between the parties by contacting the Company in any way in connection with the Services provided, for example by filling in and sending the "Contact Us" or "Contact" form on the Website, by e-mail, by telephone, or through WhatsApp, Messenger or other contacts of the Company (hereinafter referred to as the "Demand").`,
          )}
        </li>
        <br />
        <li>
          {t(
            `3.2 The Company contacts the Client without undue delay after receiving the Demand and specific conditions about providing the Services will be agreed on through the primaly selected channel of communication, respectively by e-mail if possible. Communication by e-mail is prefered by the Company.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `3.3 The Client undertakes to provide the Company with truthful information about the acreage, current status and safety of the subject of cleaning, including any non-standard or dangerous elements of the subject of cleaning (equipment, construction). The Client undertakes to notify the Company in advance of any equipment or structural elements that are susceptible to damage or destruction during the performance of the Services, or of any facts that could in any way endanger the life or health of the Employees. In the event that the Client does not notify in writing before the start of the Service, the Client bears all responsibility for any damage or injury caused.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `3.4 By concluding the Agreement, the Client expressly declares and confirms that there are no equipment, decorations, construction elements or any other elements in the place of the Cleaning, the value of which would exceed half of the Company's insurance at the time of the Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `3.5 The Client undertakes to place any irreparable or irreplaceable things or things of special popularity so that they are out of reach of the Employees.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `3.6 The Agreement on the provision of Services is concluded at the moment when the Client orders the Service in person, online on the Website or by telephone, the Services are specified between the parties and a confirmation e-mail is sent to the Client by MAGNOLIA.`,
          )}
        </li>
        <br />
        <li>{t(`3.7 Orders for the Services are considered binding.`)}</li>
        <br />
        <li>
          {t(
            `3.8 The Agreement is concluded for an indefinite period in the case of regular provision of Services, in the case of one-time provision of Services for the time necessary to perform the Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `3.9 By concluding the Agreement, the Client expressly declares and confirms that he is entitled to conclude the Agreement and that he is acquainted with all conditions, including these GTC.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('4 Cleaning, its types, Cleaning performance')}</strong>
        <br />
        <li>
          {t(
            `4.1 The "Regular cleaning" Service includes: vacuuming of kitchen and room floors; wiping kitchen, room and bathroom floors; microwave cleaning (inside); removal of rubbish from rubbish bins; sink cleaning; washing kitchen appliances (outside); refrigerator wash (outside), oven wash (outside); surface cleaning; dust cleaning; removal of cobwebs to normal ceiling height; making the bed; bath / shower cleaning; cleaned washbasins; polishing mirrors; toilet wash ..`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.2 The "General cleaning" Service includes: removal of limescale and other deep dirt from: shower heads, bathroom tiles, kitchen counters, taps; indoor deep cleaning of microwave oven, refrigerator; washing windows and blinds; washing the door..`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.3 The "Carpet and Upholstery Cleaning" Service includes: stain removal; antibacterial treatment; odor reduction / elimination; restoring saturation and hue; removal of allergens and extermination of mites; dirt removal. Everything always with a specifically selected piece of furniture ..`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.4 The "Cleaning of common areas of the house" Service includes: wiping the floors of common parts of the house; wiping stairs; cleaning the interior of elevators; wiping common door handles ..`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.5 The "Commercial space cleaning" service includes the Services according to the specific agreement and the Client's needs to the extent of.`,
          )}
        </li>
        <br />
        <li>{t(`4.1 GTC, or according to.`)}</li>
        <br />
        <li>
          {t(
            `4.2 GTC or according to the agreement, Services can be provided regularly and so-called "night cleaning" can be arranged ..`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.6 The Client acknowledges that the Services and their Prices are set for the usual pollution of premises and equipment, during the normal operation of a normal residential or non-residential space, or for the normal use of equipment. The Client is obliged to notify the Company in advance of any unusual or heavy pollution, otherwise the Employee is entitled to terminate the Cleaning immediately without its commencement / completion and the Client is obliged to pay the Company the full Cleaning Price. .`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.7 For unusual or severe pollution of premises and / or equipment, the Company is entitled to charge a surcharge of 30% of the Cleaning Price, or to refuse such cleaning, or to terminate it immediately without its commencement / completion and the Client is obliged to pay the Company the full Cleaning Price.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.8 Employee selected by the Company will arrive at the agreed place at the agreed time (or the agreed time period, see item 7 of the GTC) and will perform the agreed Service at this place.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.9 Employee will perform the Service in a reasonable time corresponding to the scope of the Cleaning. The Employee proceeds with reasonable care so that no damage is caused to the Client during the provision of the Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.10 Employee uses common cleaning products and common cleaning tools.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.11 Client is obliged to provide the company employee with all necessary cooperation free of charge during the cleaning, which consists in particular in`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.11.1 ensuring seamless access to the place of provision of the Services;.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.11.2 ensuring sufficient freedom of movement for the worker at the place of provision of Services;.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.11.3 ensuring the safe movement of the worker at the place of provision of Services ;.`,
          )}
        </li>
        <br />
        <li>{t(`4.11.4 ensuring access to cleaning aids;.`)}</li>
        <br />
        <li>
          {t(
            `4.11.5 ensuring access to a source of water, electricity and other energy needed to carry out the Cleaning;.`,
          )}
        </li>
        <br />
        <li>{t(`4.11.6 provision of a sink or similar device;.`)}</li>
        <br />
        <li>
          {t(`4.11.7 providing instructions for storing things and equipment.`)}
        </li>
        <br />
        <li>
          {t(
            `4.12 In the event that the Client does not provide the Employee with the necessary cooperation according to point`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.11 the GTC, even after notification by the Employee, the Employee is entitled to terminate the Cleaning immediately without its commencement / completion and the Client is obliged to pay the Company the full Cleaning Price.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.13 The Client is entitled to give the Employee reasonable instructions related to the Cleaning and to control the execution of the Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.14 The Client is not entitled to expand the range of services on site; it is possible to narrow down the Services, but the Client does not have the right to a refund of the Price for unexecuted ordered Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.15 In the event that the Client witnesses any actions of the Employee that could, even if only potentially, endanger the life, health of a person or animal, or damage to the property of any person, the Client is obliged to take immediate action, even if the possibility of possible violation or damage to the protected interests (property, health, life, etc.) notified the Company or the Employee in advance.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `4.16 The Client is obliged to ensure that the Subject of Cleaning is always taken over immediately after the End of Cleaning. In the event that due to reasons on the part of the Client (eg due to the Client's absence) the Subject of Cleaning is not taken over immediately, the Cleaning is considered to have been duly performed and handed over.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('5 Additional services')}</strong>
        <br />
        <li>
          {t(`5.1 The Company provides the following additional Services:`)}
        </li>
        <br />
        <li>
          {t(
            `5.1.1 "Window washing" includes: washing of all common types of windows in the range specified in advance by the Client, up to a height of 5 metres.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `5.1.2 "Ironing" includes: ironing and folding selected clean items of clothing. The Client is obliged to warn the Worker about pieces prone to damage by ironing / folding / handling. The Client undertakes not to hand over to the Employee pieces of clothing at a price exceeding CZK 2,500, nor pieces of clothing of special popularity. The Client undertakes to provide the Ironing Worker with his own functional iron and ironing board.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `5.1.3 Other specific services according to a specific written agreement with the Client.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `5.2 All additional Services are provided at an additional cost. for details on the price, see Article 10 of the GTC.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('6 Cleaning supplies and equipment')}</strong>
        <br />
        <li>
          {t(
            `6.1 The price of regular cleaning products is included in the cleaning price.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `6.2 Cleaning equipment (mop, vacuum cleaner, bucket, etc.) is provided by the Company to the Client for an additional fee according to the current Price List.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('7 Place and time of providing the Services')}</strong>
        <br />
        <li>
          {t(
            `7.1 As part of the order process, the Client specifies the date and preferred cleaning time. The Company will inform the Client of the expected Time Period for the commencement of the provision of the Services. The Client will confirm the specific Time Range. The time period is only indicative, the provision of Services may be started by the Company at any time within this range.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `7.2 The Company does not guarantee to the Client that the Cleaning will be started or completed at the exact time and reserves the right to change the time or duration of the Cleaning at any time before the start of the Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `7.3 The Company will provide the Client with the Service at the address specified by the Client. The Client is obliged to provide the Company with the exact address and contact details so that there can be no doubt on the part of the Company or the Employee about the exact place where the Cleaning is to be performed.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `7.4 The Client is obliged to notify the Company in advance in writing (especially within the order) if the place where the Services are to be provided is in any way less accessible or if access to the place may pose any problems for the average person. In the event that the Client requires the provision of Cleaning, including equipment, it is necessary that it is possible to park the vehicle at the place of Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `7.5 The Client is obliged to be available to the Employee at the specified contacts (especially by telephone) in good time before agreeing on the Time limit for starting to provide Services (so that, if necessary, the Client's employee can contact the Client at any time regarding more detailed time or place of Cleaning).`,
          )}
        </li>
        <br />
        <li>
          {t(
            `7.6 Prior to each Cleaning, the Client has the right to set cleaning priorities. The Client is obliged to inform the Employee of the Cleaning's priorities whenever the Employee requests it, for example in the event that the scope of the ordered Services would for any reason (eg due to space, pollution of the premises, etc.) exceed the standard time which the Company has reserved for a specific Cleaning Employee. In the event that the Client does not inform the Employee of the Priority for Cleaning for any reason, the Employee is entitled to determine them himself. In such a case, the Client has no right to complain about the non-performed Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `7.7 In the event that the Client does not provide the Company or the Employee with the exact data necessary to perform the Service, or in the event that the Client cannot be contacted by telephone, or in the event that the Client does not provide specifics or conditions under section 7.4 of the GTC, without its commencement / completion and the Client is obliged to pay the full Cleaning Price to the Company.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('8 Cleaning in the absence of the Client')}</strong>
        <br />
        <li>
          {t(
            `8.1 The Company strongly recommends that the Cleaning always take place in the presence of the Client or a person authorized by him.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `8.2 At the express request of the Client, it is possible for the Cleaning to take place without his presence. By such a request, the Client expressly agrees that he will not be able to exercise some of his rights related to his personal presence at the Cleaning. The Company is not liable for damage or injury caused in connection with the Client's absence at the Cleaning Site.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `8.3 If entry to the Cleaning Site cannot be secured in any other way, it is possible, at the Client's written express request and responsibility, for the Client to hand over to the Employee the keys or other data necessary to access the Cleaning Site. The Client shall always hand over only one copy of the key to the Employee and shall hand over only those keys that are necessary for the performance of the Cleaning. By handing over the keys or data, the Client confirms that these are provided with copy protection.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('9 Regular provision of Services')}</strong>
        <br />
        <li>
          {t(
            `9.1 In the event that the Client would like the Services to be provided to him on a regular basis, it is possible to enter into a written agreement with the Company on the regular provision of services. In such a case, this contract has a special status in relation to the Price List and these GTC.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `9.2 These GTC are used where the special contract on the regular provision of services does not stipulate otherwise.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `9.3 By signing the contract on the regular provision of services, the Client confirms that he has read the current wording of the GTC.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('10 Price of Services')}</strong>
        <br />
        <li>
          {t(
            `10.1 Specific information about the Services, including their current prices, is provided on the MAGNOLIA Website. Prices remain valid as long as they are displayed on the website.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `10.2 Clause 10.1 does not limit MAGNOLIA's ability to agree on individual conditions for the provision of Services with a specific Client. However, any individual conditions must be agreed in writing similarly, otherwise the conditions according to these GTC and the current price according to the Price List apply.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `10.3 The Client is obliged to always get acquainted with the current Price List before the Service is provided to him.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `10.4 In the event that the Price of a specific Service is not stated in the Price List, the Client will be informed of its amount before the Service is provided to him.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `10.5 The Company will inform the Client of the Cleaning Price within the order based on the scope of the Services requested, the nature of the premises, the required Additional Services, the structure or the distance of the Subject of Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `10.6 In the event that it would be necessary to modify the Cleaning Price on the spot in any case on the spot (especially for various reasons to increase compared to the Price List, eg to increase due to greater pollution) the Client will be informed before performing a specific Service. In the event that the Client does not agree with the modification of the Price, the Employee is not obliged to perform the Service, but the Client is not entitled to a refund of the Price for the non-performed ordered Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `10.7 In addition to the Cleaning Price, duly performed Additional Work, agreed by the Client, will also be charged. The Client may approve the Supervision in person on the spot, by telephone or electronically.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('11 Payment Terms')}</strong>
        <br />
        <li>
          {t(
            `11.1 The price for a one-time Service is payable no later than three days from the date on which the Cleaning was performed, unless the parties agree otherwise in writing. Normally, the Client pays the Cleaning Price directly after the receipt on the day of Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `11.2 In the case of regular provision of Services, the Price of Cleaning, including any Additional Work, is payable according to the specific conditions of the contract on regular provision of services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `11.3 The Client's obligation to pay the Cleaning Price, including any Additional Work, is fulfilled at the moment the relevant amount is credited in full to the MAGNOLIA account.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `11.4 In the event of the Client's delay in paying the Price, MAGNOLIA reserves the right to temporarily suspend the provision of the Services, until the full amount due has been paid. In the event of repeated delay in payment of the Price, MAGNOLIA is entitled to completely terminate the provision of the Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `11.5 It is necessary to make a written record of any discounts from the Price of Services provided to the Client, signed by an authorized person on behalf of MAGNOLIA (listed on the MAGNOLIA Website), discounts cannot be combined with each other.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `11.6 The client undertakes to pay the invoice within the due date. The client, who is not a consumer, undertakes to pay the Company a contractual penalty of 0.1% of the amount due for each day of delay in the event of late payment of the invoice until full payment of the amount due, without prejudice to the right to compensation for delay.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('12 Rules for the provision of Services')}</strong>
        <br />
        <li>
          {t(
            `12.1 MAGNOLIA reserves the right to provide the Services in accordance with its standards, in particular to apply methods and techniques at the discretion of MAGNOLIA, resp. discretion of the specific Employees who will provide the Service.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `12.2 The Employee is an expert in providing the Services and in certain cases may inform the Client of the opinion with regard to the required method of implementation or the expected consequences of the required Services in the specific environment of the Client. MAGNOLIA is not responsible for any consequences of performing the Service at the Client's request after the Client's Employee has explicitly pointed out possible problems.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `12.3 MAGNOLIA has the right to choose any person of a specific Employee, or Employees who provide the Services and reserves the right to change this person at any time before the provision of the Service, even without prior notice to the Client.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `12.4 However, MAGNOLIA will always try, based on the Client's request for repeated provision of Services, to ensure that the services are performed by an Employee who has performed the Service in the past; however, the Company does not guarantee the provision of a specific employee.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `12.5 The Person of the Employee may in no case be a reason for a complaint about the Services.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('13 Obligations of the Client')}</strong>
        <br />
        <li>
          {t(
            `13.1 The Client is obliged to actively cooperate with MAGNOLIA and provide cooperation so that the Service can be properly provided. In particular, the Client is obliged to meet the obligations set out in these GTC and to communicate all material facts that could affect the provision of Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `13.2 In the event that the Client indicates in communication with the Company that he has his own cleaning aids and equipment (mop, vacuum cleaner, iron, ironing board, etc.), the Client is obliged to make them clean, usable for cleaning, functional, safe and easy to use. The Company is not liable for any damages caused due to the use of the Client's aids. The Company is not liable for any damages to the Client's tools.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `13.3 The Client is obliged to act in accordance with these GTC, the Operating Rules of the building where the Service is provided, the MAGNOLIA rules published on the Website, in accordance with the individually agreed conditions and in accordance with the Employee's professional instructions. Otherwise, the provision of the Service may be terminated immediately, without compensation.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `13.4 MAGNOLIA is not liable for damage or injury to health or property that would arise from the Client or a person authorized by him not complying with the conditions of these GTC, MAGNOLIA Rules published on the Website, the Operating Rules of the object, or based on the Client's explicit instruction.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('14 Liability for defects and complaints')}</strong>
        <br />
        <li>
          {t(
            `14.1 MAGNOLIA is responsible for defects in the Services in accordance with applicable laws of the Czech Republic.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `14.2 The defect of the Service may be, in particular, its performance in a quantity other than the agreed quantity or in a quality other than the agreed quality (ie, for example, failure to provide part of the agreed scope of the Services, performance of the Services in lower than medium quality).`,
          )}
        </li>
        <br />
        <li>
          {t(
            `14.3 Any defects of the Service must be objected to by the Client already during the provision of the Cleaning, in which case the Employee will, if possible, immediately eliminate the defects. If the elimination of cleaning defects is not immediately possible, the Client is obliged to immediately describe all defects with sufficient certainty and ensure photo documentation of these defects so that their existence is obvious from this.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `14.4 In the event that the objection of defects is not possible during the Cleaning, the Client shall notify the Company of the defects in writing immediately after the Cleaning End, but no later than within 24 hours from the End of Cleaning. The client is obliged to exercise his rights from liability for defects in writing (especially by e-mail) and attach photo documentation of these defects so that the existence of defects is obvious.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('15 Withdrawal from the contract')}</strong>
        <br />
        <li>
          {t(
            `15.1 Client may withdraw from the Agreement until the Employee arrives at the place of Cleaning. In the event of withdrawal from the Agreement within 48 hours prior to the beginning of the Time Period for the commencement of the provision of Services, the Client is obliged to pay the Company half of the Cleaning Price. In the event of withdrawal from the Agreement within 12 hours prior to the beginning of the Time Period for the commencement of the provision of Services, the Client is obliged to pay the Company the full Cleaning Price.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `15.2 Client expressly agrees to this and requests that the Services be provided to him at a time when it would be possible to withdraw from the contract (ie before the expiry of the period of 14 days for withdrawal from the contract for consumers).`,
          )}
        </li>
        <br />
        <li>
          {t(
            `15.3 In order for the withdrawal from the Contract to be considered proper, the cancellation must be demonstrably available to MAGNOLIA. In the event that the withdrawal from the Agreement cannot be considered proper, the Client is obliged to pay the Company the full Cleaning Price.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `15.4 The Company has the right to withdraw from the Agreement at any time until the start of the Cleaning. Upon withdrawal from the Agreement, the Company is not obliged to provide the Client with replacement Cleaning, or to provide him with any performance, or to guarantee him any other date of Cleaning. However, the Company will always try to arrange, in agreement with the Client, another suitable cleaning date under similar conditions.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `15.5 It is not possible to withdraw from the Contract if the Employee has already arrived at the place of the Cleaning or the Cleaning has already started.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('16 Liability for damage')}</strong>
        <br />
        <li>
          {t(
            `16.1 MAGNOLIA is liable to the Client for damage to its property caused by the actions of the Employees in connection with the performance of the Agreement in accordance with applicable legal regulations.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `16.2 The Company is insured up to CZK 5,000,000 in the event of damage. The Client declares that he does not anticipate that damage in excess of this amount could occur during the Cleaning.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `16.3 In the event of any damage, the Client is obliged to inform MAGNOLIA in writing, no later than within 48 hours from the end of the Cleaning. The Client's claim about the damage must always be substantiated (for example, photographs "before" and "after") so that there is no doubt that the damage has occurred, in causal connection with the Employee's actions.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `16.4 MAGNOLIA will not take into account notifications of damage made in violation of clause 16.3 of the GTC.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `16.5 Damage will be primarily replaced by the Company by bringing it to its previous condition, within a period appropriate to the extent of the damage incurred, or by reimbursing the damage incurred as part of the insurance indemnity.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `16.6 Client is obliged to provide the Company with all necessary cooperation to prove the occurrence of damage (provision of assessments, expert reports, photo documentation, access of persons, etc.) required by the Company's insurance company.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('17 Voucher for Services')}</strong>
        <br />
        <li>
          {t(
            `17.1 Voucher entitles its holder to be provided with MAGNOLIA Services, or entitles the holder or a person (recipient) specified by him to obtain a specific performance specified on the Voucher.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.2 The voucher is issued by MAGNOLIA in electronic or physical form and can only be used for MAGNOLIA Services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.3 The Voucher is transferable to a third party, unless otherwise specified on the Voucher. By transferring the voucher to a third party (eg by donation), this person enters into all rights and obligations related to the Voucher.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.4 The value of the Voucher is explicitly stated. MAGNOLIA informs that the Prices may change over time, the Price is considered to be the one valid at the time the Voucher is applied, not the Price valid at the time the Voucher is issued.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.5 The voucher can be redeemed by submitting it to MAGNOLIA, at the latest when ordering the Service and before concluding the contract for the provision of Services. Subsequent (additional) Vouchers submitted will not be taken into account.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.6 The Voucher can be redeemed only once, its redemption cannot be redeemed unless expressly stated otherwise on the Voucher.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.7 The voucher can only be redeemed by the first person to redeem it.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.8 The value of the Voucher does not bear interest, the rate of inflation between the purchase and use of the Voucher is not taken into account.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.9 The voucher cannot be returned against payment of its value in cash. The Voucher Holder is not entitled to demand payment of the Voucher in cash. The unconsumed value of the Voucher is not returned.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.10 Unless expressly stated otherwise on the Voucher, MAGNOLIA does not guarantee the Voucher holder the term of the Service.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.11 Unless otherwise stated on the Voucher, the validity of the Voucher is 1 year from its issue. After this period, the rights associated with the Voucher expire without compensation.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `17.12 The Consumer has the option to withdraw from the contract within 14 days of the contract for the purchase of a Voucher, provided that the Voucher has not been redeemed at that time. In such a case, MAGNOLIA will cancel the voucher and return the value of the Voucher in cash to the Consumer.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('18 Protection of personal data')}</strong>
        <br />
        <li>
          {t(
            `18.1 The company declares that it processes personal data in accordance with Regulation 2016/679 of the European Parliament and of the Council on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46 / EC (General Data Protection Regulation) ( hereinafter referred to as the "Regulation").`,
          )}
        </li>
        <br />
        <li>
          {t(
            `18.2 The legal title for the processing of personal data is in particular the fulfillment of the Agreement between MAGNOLIA as the controller of personal data and the Client as the subject of personal data within the meaning of Article 6, paragraph 1, letter b) Regulation and further legitimate interest of MAGNOLIA according to Art. f) Regulation.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `18.3 More information on personal data protection can be found on the MAGNOLIA Website in the "GDPR" section.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('19 Communication with the Client and delivery')}</strong>
        <br />
        <li>
          {t(
            `19.1 Communication between MAGNOLIA and the Client takes place mainly via the e-mail provided by the client in the order.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `19.2 Information or communication sent by the Client to MAGNOLIA with the effect of the creation, change or termination of rights and obligations must always be made in writing by e-mail or through postal services, unless otherwise specified in these GTC.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `19.3 In case of doubt, a document provably sent by e-mail or by postal services on the third day after it is sent shall be deemed to have been received.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `19.4 The client undertakes to provide MAGNOLIA with valid, current and accurate contact details and to report their change without delay.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t("20 Method of handling Clients' complaints")}</strong>
        <br />
        <li>
          {t(
            `20.1 In the event that the Client is not satisfied with the conduct of the Employee, he may notify the Company of this fact at any time. The Company undertakes to deal with such notification at all times and to take appropriate measures from it.`,
          )}
        </li>
        <br />
        <strong>{t(`21 Information for consumers`)}</strong>
        <br />
        <li>
          {t(
            `21.1 The Client, who is a natural person and who has entered into an Agreement on the provision of Services outside his business activities, is a consumer.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `21.2 In the event that the Client, as a consumer, enters into a Contract with the Company, at a distance, he has the right to withdraw from the Contract without giving a reason. The deadline for withdrawal from the contract is 14 days from the date of concluding the contract for the provision of the Service. In the event of withdrawal from the Contract concluded at the MAGNOLIA establishment, in the event of withdrawal from the Contract, the procedure shall be in accordance with Act 89/2012 Coll. Civil Code.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `21.3 The period of 14 days for withdrawal from the contract begins to run from the date of conclusion of the Contract.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `21.4 Withdrawal from the contract must be delivered to the Company in writing via e-mail or postal services.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `21.5 The Client expressly agrees that the Services should be provided to him within the period for withdrawal from the Agreement. It is not possible to withdraw from the Contract if the Employee has already arrived at the place of the Cleaning or the Cleaning has already started.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `21.6 The company is determined to resolve any disputes amicably. If the dispute cannot be settled amicably, the Client has the right to seek out-of-court settlement of the dispute with the Czech Trade Inspection Authority, which is the competent body for out-of-court settlement of consumer disputes. The form and more detailed information are available on the website of the Czech Trade Inspection Authority`,
          )}{' '}
          <a href="https://www.adr.coi.cz">https://www.adr.coi.cz</a>{' '}
          {t(` . The Client may submit a complaint via the website`)}{' '}
          <a href="https://www.coi.cz/podatelna/">
            https://www.coi.cz/podatelna/.
          </a>
        </li>
      </p>
      <p>
        <strong>{t('22 Binding nature of the GTC')}</strong>
        <br />
        <li>
          {t(
            `22.1 These GTC are available on the Company's Website and are sent to the Clients in the current version individually by e-mail each time the Agreement is first concluded.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `22.2 The Client confirms that before the Service has been provided to him, he has read the wording of the GTC and agrees with their wording.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `22.3 In the case of regular provision of Services, the Client confirms that the GTC were submitted to him at the first conclusion of the contract on regular provision of services and were attached to this contract.`,
          )}
        </li>
      </p>
      <p>
        <strong>{t('23 Final Provisions')}</strong>
        <br />
        <li>
          {t(
            `23.1 All agreements between the parties are governed by the laws of the Czech Republic. If the relationship contains an international element, then the parties agree that the relationship is governed by the law of the Czech Republic. This does not affect the consumer's rights under generally binding legislation.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `23.2 MAGNOLIA is entitled to unilaterally change or supplement these GTC.`,
          )}
        </li>
        <br />
        <li>
          {t(
            `23.3 MAGNOLIA is obliged to notify the change of the GBC by publishing the new version of the GBC on its Website and sending the new version of the GBC to existing Clients during the process of arranging another Cleaning with an explicit comment that the GBTC have been updated. By concluding the Agreement, the Client agrees with the effective wording of the GTC. In the case of regular provision of services on the basis of a contract on regular provision of services, the Company will send a new version of the GTC to the Client by e-mail. The Client is entitled to reject the change of the GTC so notified in writing within one month from the date of its notification, otherwise it is valid that he agrees with the change; in the event of a refusal to change the GTC, the Company is entitled to unilaterally terminate the contract and the regular provision of services.`,
          )}
        </li>
        <br />
        <li>{t(`23.4 These GTC take effect on 1.1.2022.`)}</li>
      </p>
    </>
  );
}
